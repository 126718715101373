import React from "react";
import { FAQSection } from "../components/common/FAQSection";
import { FAQData } from "../types/faq";
import faqs from "../data/faqs.json";
import { SEOHelmet } from "../components/common/SEOHelmet";

export default function FAQsPage() {
  const faqData = faqs as FAQData;

  return (
    <>
      <SEOHelmet page="faq" />
      <div className="relative isolate overflow-hidden">
        <FAQSection faqs={faqData.faqs} />
      </div>
    </>
  );
}
