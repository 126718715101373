import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { LogoSvg } from "../svgs/LogoSvg";
import { navigationItems, PATH_CONTACT } from "../../utils/constants";
import { PrimaryButton } from "../common/PrimaryButton";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

export const Header = () => {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Close mobile menu when route changes
  useEffect(() => {
    setMobileMenuOpen(false);
  }, [location.pathname]);

  // Lock body scroll when mobile menu is open
  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [mobileMenuOpen]);

  // Periodic animation effect
  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(true);
      setTimeout(() => setAnimate(false), 2000); // Animation duration
    }, 30000); // Repeat every 30 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="h-16 sm:h-24" />

      <header
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-500 ease-in-out
          bg-white dark:bg-gray-800/50 border-b border-gray-100 dark:border-gray-700 
          ${
            scrolled
              ? "bg-gradient-to-b from-white via-white to-ergo-50 dark:from-gray-900 dark:via-gray-800 dark:to-gray-700 shadow-sm"
              : "bg-white dark:bg-gray-800"
          }`}
      >
        <nav>
          <div className="flex h-16 sm:h-24 items-center justify-between bg-white dark:bg-gray-800 px-6 sm:px-8 lg:px-12">
            {/* Logo - Left aligned */}
            <div className="flex-shrink-0">
              <Link to="/" className="flex items-center gap-4">
                <span className="sr-only">ERGOTHERAPIE UND ZEIT(T)RÄUME</span>
                <LogoSvg classNames="h-7 sm:h-9 xl:h-11 w-auto fill-current text-ergo-500 dark:text-ergo-400 transition-transform duration-300 hover:scale-105" />
                <div className="flex xl:hidden 2xl:flex flex-col">
                  <span className="text-xs sm:text-sm xl:text-base font-medium tracking-wider text-gray-900 dark:text-white">
                    ERGOTHERAPIE
                  </span>
                  <span className="text-xs sm:text-sm xl:text-base font-medium tracking-wider text-ergo-600 dark:text-ergo-400">
                    UND ZEIT(T)RÄUME
                  </span>
                </div>
              </Link>
            </div>

            {/* Navigation Links - Centered with flex-grow */}
            <div className="flex-grow flex justify-center">
              <div className="hidden xl:flex xl:items-center xl:space-x-8 2xl:space-x-12">
                {navigationItems.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={`relative px-2 py-2 text-sm xl:text-base tracking-wide transition-all duration-300 group
                      ${
                        location.pathname === item.href
                          ? "text-ergo-600 dark:text-ergo-400 font-medium"
                          : "text-gray-600 dark:text-gray-300 hover:text-ergo-600 dark:hover:text-ergo-400 font-normal"
                      }`}
                  >
                    {item.name}
                    <span
                      className={`absolute bottom-0 left-0 w-full h-0.5 rounded-full transform origin-left transition-transform duration-300 ease-out
                        ${
                          location.pathname === item.href
                            ? "bg-ergo-400 scale-x-100"
                            : "bg-ergo-300 scale-x-0 group-hover:scale-x-100"
                        }`}
                    />
                  </Link>
                ))}
              </div>
            </div>

            {/* Contact Button - Right aligned */}
            <div className="flex-shrink-0 hidden xl:block">
              <PrimaryButton to={PATH_CONTACT} periodic>
                Kontakt
              </PrimaryButton>
            </div>

            {/* Mobile menu button */}
            <div className="flex xl:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md p-2 text-ergo-600 dark:text-ergo-400"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <span className="sr-only">Menü öffnen</span>
                {mobileMenuOpen ? (
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <Bars3Icon className="h-5 w-5" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>

          {/* Mobile menu panel */}
          <div
            className={`fixed inset-0 z-50 xl:hidden bg-white/95 dark:bg-gray-900/95 backdrop-blur-sm shadow-[0_8px_30px_rgb(0,0,0,0.04)] dark:shadow-[0_8px_30px_rgb(0,0,0,0.1)] transition-all duration-300 ease-in-out 
              ${mobileMenuOpen ? "opacity-100 translate-x-0" : "opacity-0 -translate-x-full"}`}
            style={{ top: "64px" }}
          >
            <div className="flex flex-col h-full">
              {/* Navigation Links */}
              <div className="flex-grow overflow-y-auto px-6 pt-8 pb-8">
                <div className="flex flex-col sm:grid sm:grid-cols-2 sm:gap-4">
                  {navigationItems.map((item, index) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={`relative overflow-hidden transition-all duration-300 group
                        ${index > 0 ? "mt-3 sm:mt-0" : ""}
                        ${
                          location.pathname === item.href
                            ? "bg-ergo-50/50 dark:bg-gray-800/50"
                            : "hover:bg-gray-50 dark:hover:bg-gray-800/30"
                        }`}
                    >
                      <div className="flex flex-col items-center justify-center p-4 sm:p-6 text-center min-h-[60px] sm:min-h-[120px]">
                        <span
                          className={`text-lg tracking-wide transition-all duration-300
                          ${
                            location.pathname === item.href
                              ? "font-bold text-ergo-600 dark:text-ergo-400"
                              : "font-medium text-gray-600 dark:text-gray-300 group-hover:text-ergo-600 dark:group-hover:text-ergo-400"
                          }`}
                        >
                          {item.name}
                        </span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>

              {/* Contact Button */}
              <div className="p-6">
                <PrimaryButton
                  to={PATH_CONTACT}
                  periodic
                  className="w-full shadow-[0_4px_20px_-2px_rgba(139,211,230,0.3)] dark:shadow-[0_4px_20px_-2px_rgba(139,211,230,0.15)]"
                >
                  <span className="text-lg py-2">Kontakt</span>
                </PrimaryButton>
              </div>
            </div>
          </div>

          {/* Background overlay when mobile menu is open */}
          <div
            className={`fixed inset-0 bg-black/10 dark:bg-black/20 backdrop-blur-sm transition-opacity duration-300 xl:hidden
              ${mobileMenuOpen ? "opacity-100" : "opacity-0 pointer-events-none"}`}
            style={{ top: "64px" }}
            onClick={() => setMobileMenuOpen(false)}
          />
        </nav>
      </header>
    </>
  );
};
