import React from "react";
import { Link } from "react-router-dom";
import { PATH_ABOUT } from "../../utils/constants";
import { ArrowRightIcon } from "@heroicons/react/24/outline";

interface TeamMemberPreviewProps {
  src: string;
  name: string;
  brightness?: string;
  className?: string;
}

const TeamMemberPreview: React.FC<TeamMemberPreviewProps> = ({ src, name, brightness = "", className = "" }) => (
  <div className={`group relative max-w-[800px] mx-auto ${className}`}>
    <div className="aspect-[16/9] rounded-2xl overflow-hidden shadow-md bg-white dark:bg-gray-800">
      <img
        src={process.env.PUBLIC_URL + src}
        alt={name}
        className={`w-full h-full object-cover transition-transform duration-500 group-hover:scale-105 ${brightness}`}
      />
    </div>
  </div>
);

export const TeamPreviewSection = () => {
  return (
    <section className="overflow-hidden" aria-label="Unser Team">
      <div className="mx-auto px-6 lg:px-8">
        {/* Text Content */}
        <div className="max-w-3xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">Unser Team</h2>
          <p className="mt-6 text-lg leading-7 text-gray-600 dark:text-gray-300">
            Erfahrene Ergotherapeutinnen, die sich leidenschaftlich für Ihre ganzheitliche Betreuung einsetzen. Jede
            Therapeutin bringt ihre individuellen Stärken und Fachgebiete ein, wodurch wir eine umfassende Versorgung
            gewährleisten können.
          </p>
          <p className="mt-6 text-lg leading-7 text-gray-600 dark:text-gray-300">
            Gemeinsam arbeiten wir daran, Sie auf Ihrem Weg zu unterstützen.
          </p>
          <div className="mt-8">
            <Link
              to={PATH_ABOUT}
              className="inline-flex items-center gap-2 text-ergo-600 hover:text-ergo-500 dark:text-ergo-400 dark:hover:text-ergo-300 font-medium transition-colors duration-300"
            >
              <span>Mehr über unser Team erfahren</span>
              <ArrowRightIcon className="h-4 w-4" />
            </Link>
          </div>
        </div>

        {/* Team Image */}
        <div className="mt-16">
          <TeamMemberPreview
            src="/images/uns.jpg"
            name="Team"
            className="transform hover:-translate-y-2 transition-transform duration-300"
          />
        </div>
      </div>
    </section>
  );
};
