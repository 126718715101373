import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../utils/utils";

export interface ImageItem {
  src: string;
  alt: string;
}

interface ImagePagerProps {
  images: ImageItem[];
  className?: string;
}

export const ImagePager: React.FC<ImagePagerProps> = ({ images, className = "" }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  return (
    <>
      {/* Desktop Image Grid */}
      <div className={classNames("hidden lg:contents", className)}>
        {images.map((image, index) => (
          <figure key={index} className="group transform hover:-translate-y-2 transition-transform duration-300">
            <div className="rounded-2xl overflow-hidden shadow-md bg-white dark:bg-gray-800">
              <img
                src={process.env.PUBLIC_URL + image.src}
                alt={image.alt}
                className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105 aspect-[4/3]"
              />
            </div>
          </figure>
        ))}
      </div>

      {/* Mobile Image Pager */}
      <div className="relative lg:hidden">
        <div className="overflow-hidden rounded-2xl">
          <div className="relative">
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentImageIndex * 100}%)` }}
            >
              {images.map((image, index) => (
                <figure key={index} className="w-full flex-shrink-0">
                  <div className="rounded-2xl overflow-hidden shadow-md bg-white dark:bg-gray-800">
                    <img
                      src={process.env.PUBLIC_URL + image.src}
                      alt={image.alt}
                      className="w-full h-full object-cover aspect-[4/3]"
                    />
                  </div>
                </figure>
              ))}
            </div>

            {/* Navigation Buttons */}
            <div className="absolute inset-0 flex items-center justify-between p-4">
              <button
                onClick={previousImage}
                className="p-2 rounded-full bg-white/80 dark:bg-gray-800/80 text-gray-800 dark:text-white shadow-lg hover:bg-white dark:hover:bg-gray-800 transition-all duration-200"
                aria-label="Previous image"
              >
                <ChevronLeftIcon className="h-6 w-6" />
              </button>
              <button
                onClick={nextImage}
                className="p-2 rounded-full bg-white/80 dark:bg-gray-800/80 text-gray-800 dark:text-white shadow-lg hover:bg-white dark:hover:bg-gray-800 transition-all duration-200"
                aria-label="Next image"
              >
                <ChevronRightIcon className="h-6 w-6" />
              </button>
            </div>

            {/* Pagination Dots */}
            <div className="absolute bottom-4 left-0 right-0">
              <div className="flex justify-center gap-2">
                {images.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentImageIndex(index)}
                    className={`w-2 h-2 rounded-full transition-all duration-200 ${
                      index === currentImageIndex ? "bg-white scale-125" : "bg-white/50 hover:bg-white/75"
                    }`}
                    aria-label={`Go to image ${index + 1}`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
