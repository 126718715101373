import React from "react";
import { Link } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { PATH_FAQ } from "../../utils/constants";
import { LogoSvg } from "../svgs/LogoSvg";

interface FAQItem {
  question: string;
  answer: string;
}

interface FAQCategory {
  category: string;
  items: FAQItem[];
}

interface FAQSectionProps {
  faqs: FAQCategory[];
  showHeader?: boolean;
  compact?: boolean;
  preview?: boolean;
}

interface FAQProps {
  faq: FAQItem;
  categoryIndex: number;
  faqIndex: number;
  compact?: boolean;
}

const FAQ: React.FC<FAQProps> = ({ faq, categoryIndex, faqIndex, compact = false }) => {
  return (
    <Disclosure
      as="div"
      key={faq.question}
      className="faq-item"
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      {({ open }) => (
        <div
          className={`${
            compact
              ? "bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm rounded-xl overflow-hidden border border-gray-100 dark:border-gray-700/60 hover:border-ergo-100 dark:hover:border-ergo-800"
              : "bg-white/80 dark:bg-gray-900/80 backdrop-blur-sm rounded-xl overflow-hidden border border-gray-100 dark:border-gray-700/60 hover:border-ergo-100 dark:hover:border-ergo-800"
          } transition-all duration-200`}
        >
          <Disclosure.Button
            className={`flex w-full items-center justify-between ${
              compact ? "px-3 py-4" : "px-6 py-4"
            } text-left group transition-colors duration-300 rounded-lg`}
            aria-controls={`faq-answer-${categoryIndex}-${faqIndex}`}
            aria-expanded={open}
          >
            <span
              className={`${
                compact ? "text-base" : "text-lg"
              } font-medium text-gray-900 dark:text-white pr-8 group-hover:text-ergo-600 dark:group-hover:text-ergo-400 transition-colors duration-300`}
              itemProp="name"
            >
              {faq.question}
            </span>
            <span className="flex-shrink-0">
              <ChevronDownIcon
                className={`${
                  compact ? "h-5 w-5" : "h-6 w-6"
                } text-gray-400 group-hover:text-ergo-400 dark:text-gray-500 dark:group-hover:text-ergo-400 transition-all duration-300 ${
                  open ? "rotate-180" : ""
                }`}
                aria-hidden="true"
              />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel static itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
            <div
              className={`transition-all duration-300 ease-in-out ${
                open ? "opacity-100 max-h-[2000px] pb-2" : "opacity-0 max-h-0 overflow-hidden"
              }`}
              id={`faq-answer-${categoryIndex}-${faqIndex}`}
            >
              <div className={compact ? "px-3" : "px-6"}>
                <div
                  className={`prose ${
                    compact ? "prose-base" : "prose-lg"
                  } max-w-none dark:prose-invert [&_ul]:mt-4 [&_li]:text-gray-600 dark:[&_li]:text-gray-300 [&_p]:text-gray-600 dark:[&_p]:text-gray-300`}
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                  itemProp="text"
                />
              </div>
            </div>
          </Disclosure.Panel>
        </div>
      )}
    </Disclosure>
  );
};

export const FAQSection: React.FC<FAQSectionProps> = ({
  faqs,
  showHeader = true,
  compact = false,
  preview = false,
}) => {
  const [isExpanded, setIsExpanded] = React.useState(!preview);

  // Generate FAQ Schema for SEO
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: faqs.flatMap((category) =>
      category.items.map((faq) => ({
        "@type": "Question",
        name: faq.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: faq.answer.replace(/<[^>]*>/g, ""), // Strip HTML tags for schema
        },
      }))
    ),
  };

  return (
    <section
      className={`${compact ? "space-y-8" : "space-y-12"}`}
      aria-label="Häufig gestellte Fragen"
      role="region"
      itemScope
      itemType="https://schema.org/FAQPage"
    >
      {/* Schema.org markup */}
      <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>

      {showHeader && (
        <div className="mx-auto text-center">
          <div className="flex flex-col items-center">
            <LogoSvg
              classNames={`h-16 w-auto fill-current text-ergo-500 dark:text-ergo-400 ${compact ? "mb-6" : "mb-8"}`}
              aria-label="Ergotherapie Rottweil Logo"
            />
            <h2
              className={`text-4xl sm:text-5xl font-semibold tracking-tight text-gray-900 dark:text-white ${
                compact ? "mb-8" : "mb-12"
              } drop-shadow-sm`}
              id="faq-section-title"
            >
              Häufig gestellte Fragen
            </h2>
            <div className="prose prose-lg max-w-none dark:prose-invert">
              <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
                Falls Sie weitere Fragen haben, können Sie uns gerne{" "}
                <Link
                  to="/kontakt"
                  className="text-ergo-600 hover:text-ergo-500 dark:text-ergo-400 dark:hover:text-ergo-300 no-underline"
                >
                  kontaktieren
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      )}

      <div
        className={`relative mx-auto max-w-7xl ${compact ? "space-y-8" : "space-y-16"}`}
        aria-labelledby="faq-section-title"
      >
        {/* Wrap everything in a relative container for proper overflow handling */}
        <div className="relative">
          {/* FAQ Content Container */}
          <div className="transition-all duration-500 ease-in-out">
            {faqs.map((category, categoryIndex) => (
              <div
                key={categoryIndex}
                className={`${compact ? "space-y-6" : "space-y-8"} ${
                  categoryIndex > 0 ? (compact ? "mt-8" : "mt-16") : ""
                } ${preview && !isExpanded && categoryIndex > 1 ? "hidden" : ""}`}
                role="region"
                aria-labelledby={`faq-category-${categoryIndex}`}
              >
                <h3
                  id={`faq-category-${categoryIndex}`}
                  className="text-2xl font-semibold tracking-tight text-ergo-600 dark:text-ergo-400"
                >
                  {category.category}
                </h3>

                <div className={`grid grid-cols-1 lg:grid-cols-2 ${compact ? "gap-4 lg:gap-6" : "gap-6 lg:gap-12"}`}>
                  {/* Left Column */}
                  <div className={`${compact ? "space-y-3" : "space-y-4"}`}>
                    {category.items.slice(0, Math.ceil(category.items.length / 2)).map((faq, faqIndex) => (
                      <FAQ
                        key={faq.question}
                        faq={faq}
                        categoryIndex={categoryIndex}
                        faqIndex={faqIndex}
                        compact={compact}
                      />
                    ))}
                  </div>

                  {/* Right Column */}
                  <div className={`${compact ? "space-y-3" : "space-y-4"}`}>
                    {category.items.slice(Math.ceil(category.items.length / 2)).map((faq, faqIndex) => (
                      <FAQ
                        key={faq.question}
                        faq={faq}
                        categoryIndex={categoryIndex}
                        faqIndex={faqIndex + Math.ceil(category.items.length / 2)}
                        compact={compact}
                      />
                    ))}
                  </div>
                </div>
              </div>
            ))}

            {/* Show more button */}
            {preview && (
              <div className="mt-12 pb-8 flex justify-center">
                <Link
                  to={PATH_FAQ}
                  className="group relative inline-flex items-center justify-center overflow-visible
                    rounded-lg px-8 py-3 text-base font-medium tracking-wide text-ergo-600 dark:text-ergo-400
                    shadow-[0_2px_8px_-1px_rgba(139,211,230,0.3)] dark:shadow-[0_2px_8px_-1px_rgba(139,211,230,0.1)]
                    hover:shadow-[0_4px_20px_-2px_rgba(139,211,230,0.5)] dark:hover:shadow-[0_4px_20px_-2px_rgba(139,211,230,0.2)]
                    transition-all duration-300"
                >
                  <span>Alle Fragen anzeigen</span>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
