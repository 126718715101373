import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import { SEOHelmet } from "../components/common/SEOHelmet";
import { TeamPreviewSection } from "../components/common/TeamPreviewSection";
import { TreatmentInfoBanner } from "../components/common/TreatmentInfoBanner";
import { LogoSvg } from "../components/svgs/LogoSvg";
import { InstagramSection } from "../components/common/InstagramSection";
import { FAQSection } from "../components/common/FAQSection";
import { ImagePager } from "../components/common/ImagePager";
import faqs from "../data/faqs.json";
import { FAQData } from "../types/faq";
import { classNames } from "../utils/utils";

type Section = {
  title: string;
  text?: string;
  content?: string[];
};

const data: { sections: Section[] } = {
  sections: [
    {
      title: "Bei uns sind Sie richtig, wenn Sie...",
      content: [
        "eine individuell auf Sie und Ihre Bedürfnisse abgestimmte Ergotherapie suchen",
        "gemeinsam, individuell für ihren Alltag umsetzbare Lösungen erarbeiten wollen",
        "sich bewusst dazu entscheiden, etwas verändern zu wollen",
        "Zeiträume wieder sinnvoll auskosten wollen",
      ],
    },
  ],
};

export default function StartPage() {
  const faqData = faqs as FAQData;

  const practiceImages = [
    {
      src: "/images/start_image_1.jpeg",
      alt: "Praxisraum mit Therapieliege",
    },
    {
      src: "/images/start_image_6.jpg",
      alt: "Besprechungsraum",
    },
    {
      src: "/images/start_image_3.jpeg",
      alt: "Therapieraum mit Sprossenwand",
    },
  ];

  return (
    <>
      <SEOHelmet page="start" />

      <main className="space-y-12 sm:space-y-24">
        {/* Hero Section */}
        <section className="mx-auto px-6 lg:px-8">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-8 xl:gap-x-12 items-center">
            <div className="lg:col-span-7 xl:col-span-6">
              <header className="mb-8">
                <LogoSvg
                  classNames="h-16 w-auto fill-current text-ergo-500 dark:text-ergo-400"
                  aria-label="Ergotherapie Rottweil Logo"
                />
              </header>
              <h1
                className="text-4xl sm:text-6xl lg:text-6xl font-bold tracking-tight leading-[1.15] 
                bg-gradient-to-br from-gray-900 via-gray-800 to-ergo-600 dark:from-white dark:via-gray-100 dark:to-ergo-400 bg-clip-text text-transparent 
                drop-shadow-sm max-auto max-w-lg md:text-left 3xl:max-w-3xl"
              >
                Einzigartige Menschen, <br className="hidden sm:block" />
                <span className="text-ergo-600 dark:text-ergo-400">individuelle Therapien.</span>
              </h1>
              <div className="mt-12 sm:mt-16">
                <p className="text-lg leading-8 text-gray-600 dark:text-gray-300">
                  Herzlich willkommen in unserer Praxis für <br />
                  <span className="font-semibold text-gray-900 dark:text-white">ERGOTHERAPIE UND ZEIT(T)RÄUME</span>
                </p>
                <p className="mt-6 text-lg leading-8 text-gray-600 dark:text-gray-300">
                  Wir unterstützen, beraten, coachen und motivieren unsere Klienten:Innen in vielfältiger Weise.
                </p>
              </div>
            </div>
            <aside className="mt-10 lg:mt-0 lg:col-span-5 xl:col-span-6">
              <img
                src={process.env.PUBLIC_URL + "/images/tree.jpeg"}
                alt="Ergotherapie Rottweil - Praxisgebäude"
                className="w-full rounded-2xl object-cover shadow-xl dark:shadow-2xl dark:shadow-black/20 aspect-[4/3] max-h-[500px]"
              />
            </aside>
          </div>
        </section>

        {/* Mission Statement Section */}
        <section className="overflow-hidden pt-6" aria-label="Unsere Mission">
          <div className="mx-auto gap-x-12 lg:flex lg:px-8">
            <p className="text-xl sm:text-lg leading-8 text-gray-600 dark:text-gray-300 mb-6 lg:mb-0">
              Es ist uns sehr wichtig, dass die Therapie an Ihrem Alltag ansetzt, sodass gemeinsam erarbeitete
              Strategien zu Hause umgesetzt werden können. Wir setzen an Ihren aktuellen Bedürfnissen und Wünschen an
              und beziehen ihre bereits vorhandenen Ressourcen mit in die Therapie ein.
            </p>
            <p className="text-xl sm:text-lg leading-8 text-gray-600 dark:text-gray-300">
              Die ganzheitliche Betrachtungsweise unserer Klienten:Innen ist uns sehr wichtig. Deshalb werden nach
              Möglichkeit auch Angehörige bzw. enge Bezugspersonen mit in die Therapie integriert. Unsere
              Behandlungsschwerpunkte liegen in den Bereichen{" "}
              <span className="font-semibold text-gray-900 dark:text-white">Neurologie</span> und{" "}
              <span className="font-semibold text-gray-900 dark:text-white">Psychiatrie</span>.
            </p>
          </div>

          <div className="mt-16">
            <TreatmentInfoBanner />
          </div>
        </section>

        {/* Team Preview Section */}
        <TeamPreviewSection />

        {/* Practice Facilities Section */}
        <section className="overflow-hidden pt-12" aria-label="Unsere Praxis">
          <div className="mx-auto px-6 lg:px-8">
            <div className="grid gap-6 sm:gap-8 lg:grid-cols-2">
              <header>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                  Unsere Praxis
                </h2>
                <p className="mt-6 text-lg leading-7 text-gray-600 dark:text-gray-300 lg:pr-8">
                  Unsere Praxis ist ein Ort der Ruhe und Konzentration, gestaltet für Ihre individuellen
                  Therapiebedürfnisse. Der großzügige, reizarm gestaltete Therapieraum mit Sprossenwand und
                  professioneller Therapieliege bietet den perfekten Rahmen für therapeutische Übungen.
                </p>
                <p className="mt-4 text-lg leading-7 text-gray-600 dark:text-gray-300 lg:pr-8">
                  In unserem gemütlich eingerichteten Besprechungsraum schaffen wir eine vertrauensvolle Atmosphäre für
                  persönliche Gespräche und Beratungen.
                </p>
                <p className="mt-4 text-lg leading-7 text-gray-600 dark:text-gray-300 lg:pr-8">
                  Modernste Therapiematerialien und bewährte Konzepte ermöglichen es uns, Ihre Therapie optimal auf Ihre
                  persönlichen Bedürfnisse abzustimmen.
                </p>
              </header>

              <ImagePager images={practiceImages} />
            </div>
          </div>
        </section>

        {/* Instagram Section */}
        <InstagramSection />

        {/* FAQ Section */}
        <FAQSection faqs={faqData.faqs} showHeader={true} compact={true} preview={true} />
      </main>
    </>
  );
}
